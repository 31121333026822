<template>
    <div class="stepsdemo-content">
        <Card>
           <template v-slot:title>
                {{$t('Datos del Proyecto')}}
            </template>
            <template v-slot:subtitle>
                {{$t('solicitada')}}
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-12 p-md-12">
						<label>{{$t('Descripción')}}</label>
						<Textarea v-model="proyecto.descripcion" rows="2"/>
                        <small v-show="validationErrors.descripcion && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-3">
						<label>{{$t('Fecha de Inicio')}}</label>
                        <InputMask v-model="proyecto.fech_ini" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                        <small v-show="validationErrors.fech_ini && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-3">
						<label>{{$t('Fecha de Finalización')}}</label>
                        <InputMask v-model="proyecto.fech_fin" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
                        <small v-show="validationErrors.fech_ini && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-3">
						<label>{{$t('Contrato')}}</label>
						<FileUpload :chooseLabel="$t('Subir PDF')" uploadLabel="Cargar" mode="basic" :name="img" :url="url.api+'upload.php'"  accept="application/pdf" :maxFileSize="4000000" @upload="myUploader(img)" :auto="true"/>
                        <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
                        <small v-show="validationErrors.contrato && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-12 p-md-3">
						<img style="width: 50px;" src="images/pdf.jpg" v-if="proyecto.contrato" @click="Ver(propiedad.evaluacion)"/>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button :disabled ="$store.state.loading" :label="$t('Anterior')" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button :disabled ="$store.state.loading" :label="$t('Siguiente')" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/API";
const Consulta = new API('Cotizacion','Proyecto');
import { useI18nPlugin } from '@unify/vuex-i18n';
import Token from "uuid-token-generator";
import url from "@/service/_URL";

export default {
    props: {
        formData: Object
    },
    data () {
        return {			
            submitted: false,
            validationErrors: {},
			tipos: null,
			proyecto: {
				descripcion: null,
				contrato: null,
				fech_ini: null,
				fech_fin: null,
			},
			localidades: [],
            size: '40vw',
			img: null,
        }
    },
	created() {
        if (this.$store.state.mobile){this.size = '80vw';}
		this.i18n = useI18nPlugin();
        this.url = url;
		Consulta.Ini().then(result => {
			if (result.consult.localidades){
				this.localidades = result.consult.localidades;
			}
		}); 
		const tokgen = new Token(256);
		this.img = tokgen.generate();
	},
    methods: {
        Ver(document){
            window.open(url.upload+document+'.pdf', '_blank');
		},
        nextPage() {
            this.submitted = true;
            if (this.validateForm() ) {
                this.$emit('next-page', {formData: {proyecto: this.proyecto}, pageIndex: 1});
            }
        },
        prevPage() {

            this.$emit('prev-page', {pageIndex: 1});
        },
		myUploader(img) {
			Consulta.Procesar('RevPDF',{
				foto: img,
			}).then(response => {
				if(response.result){
					this.proyecto.contrato = img;
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
                const tokgen = new Token(256);
                this.img = tokgen.generate();
			});
		},
        validateForm() {
            if (this.proyecto.descripcion==null)
               this.validationErrors['descripcion'] = true;
            else
               delete this.validationErrors['descripcion'];
            if (this.proyecto.fech_ini.length!=10)
               this.validationErrors['fech_ini'] = true;
            else
               delete this.validationErrors['fech_ini'];
            if (this.proyecto.fech_fin.length!=10)
               this.validationErrors['fech_fin'] = true;
            else
               delete this.validationErrors['fech_fin'];
            if (this.proyecto.contrato==null)
               this.validationErrors['contrato'] = true;
            else
               delete this.validationErrors['contrato'];

            return !Object.keys(this.validationErrors).length;
        }
    }
}
</script>